import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import ScrollToTop from '../ScrollToTop';
import CookieConsent from '../CookieConsent';

const PublicLayout: React.FC = () => {
  console.log('PublicLayout render:', {
    pathname: window.location.pathname,
    search: window.location.search
  });
  return (
    <>
      <ScrollToTop />
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Outlet />
        </main>
        <Footer />
      </div>
      <CookieConsent />
    </>
  );
};

export default PublicLayout;
