import React from 'react';
import { useRouteError, isRouteErrorResponse, useNavigate } from 'react-router-dom';

export default function ErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();

  let errorMessage = 'Ocorreu um erro inesperado.';
  let errorDetails = '';
  let errorTitle = 'Ops! Algo deu errado';
  let showBackButton = true;
  let showHomeButton = true;

  // Enhanced error logging for debugging
  console.error('Route Error Details:', {
    error,
    type: typeof error,
    isRouteError: isRouteErrorResponse(error),
    stack: error instanceof Error ? error.stack : undefined,
    location: window.location.href,
    pathname: window.location.pathname,
    params: new URLSearchParams(window.location.search).toString()
  });

  // Log more details about the error
  if (error && typeof error === 'object') {
    console.error('Error object properties:', Object.keys(error));
    for (const key of Object.keys(error)) {
      console.error(`${key}:`, error[key]);
    }
  }

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 404:
        errorTitle = 'Página não encontrada';
        errorMessage = 'A página que você está procurando não existe ou foi movida.';
        break;
      case 401:
        errorTitle = 'Acesso não autorizado';
        errorMessage = 'Você precisa estar autenticado para acessar esta página.';
        showBackButton = false;
        break;
      case 403:
        errorTitle = 'Acesso negado';
        errorMessage = 'Você não tem permissão para acessar esta página.';
        break;
      default:
        errorTitle = `Erro ${error.status}`;
        errorMessage = error.statusText || 'Ocorreu um erro no servidor.';
        errorDetails = error.data?.message || '';
    }
  } else if (error instanceof Error) {
    errorMessage = error.message;
    errorDetails = error.stack || '';
  }

  const handleBack = () => {
    navigate(-1);
  };

  const handleHome = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6">
        <div className="text-center">
          <div className="mb-4">
            <svg
              className="mx-auto h-12 w-12 text-[#71100f]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            {errorTitle}
          </h2>
          
          <p className="text-gray-600 mb-4">
            {errorMessage}
          </p>

          {errorDetails && (
            <pre className="mt-4 p-4 bg-gray-50 rounded text-sm text-gray-600 overflow-auto max-h-32">
              {errorDetails}
            </pre>
          )}

          <div className="mt-6 space-x-3">
            {showBackButton && (
              <button
                onClick={handleBack}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#71100f] hover:bg-[#71100f]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#71100f]"
              >
                Voltar
              </button>
            )}
            
            {showHomeButton && (
              <button
                onClick={handleHome}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#71100f]"
              >
                Ir para Home
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
