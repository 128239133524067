import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, Truck } from 'lucide-react';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [imageError, setImageError] = React.useState(false);

  const navigation = [
    { name: 'Início', href: '/' },
    { name: 'Sobre Nós', href: '/about' },
    { name: 'Clientes', href: '/clients' },
    { name: 'Onde Estamos', href: '/location' },
    { name: 'Notícias', href: '/news' },
    { name: 'Contato', href: '/contact' },
  ];

  const carreiraButton = { name: 'Trabalhe Conosco', href: '/careers' };
  const seminovosButton = { 
    name: 'Seminovos', 
    href: '/vehicles',
    icon: Truck 
  };

  return (
    <header className="bg-white shadow-md fixed w-full top-0 z-50">
      <nav className="container mx-auto px-4 py-2">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center">
            <img 
              src="/images/logo2.png" 
              alt="Indiana Transportes"
              className="h-16 w-auto"
              onError={(e) => {
                console.error('Error loading image:', e);
                setImageError(true);
              }}
            />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex lg:items-center lg:gap-12">
            <div className="flex space-x-8">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-base font-medium text-gray-700 hover:text-[#71100f] transition-colors"
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="flex items-center gap-4">
              <Link
                to={carreiraButton.href}
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#71100f] hover:bg-[#8B1412] transition-colors"
              >
                {carreiraButton.name}
              </Link>
              <Link
                to={seminovosButton.href}
                className="inline-flex items-center justify-center px-4 py-2 border-2 border-[#71100f] rounded-md shadow-sm text-lg font-semibold text-[#71100f] hover:bg-[#71100f] hover:text-white transition-all"
              >
                <seminovosButton.icon className="w-6 h-6 mr-2" />
                {seminovosButton.name}
              </Link>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="lg:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-[#71100f] hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#71100f]"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className="sr-only">Abrir menu principal</span>
              {isMenuOpen ? (
                <X className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="lg:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-[#71100f] hover:bg-gray-50 transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.name}
                </Link>
              ))}
              <Link
                to={carreiraButton.href}
                className="block px-3 py-2 rounded-md text-base font-medium text-white bg-[#71100f] hover:bg-[#8B1412] transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                {carreiraButton.name}
              </Link>
              <Link
                to={seminovosButton.href}
                className="block px-3 py-2 rounded-md text-lg font-semibold text-[#71100f] hover:bg-[#71100f] hover:text-white border-2 border-[#71100f] transition-all"
                onClick={() => setIsMenuOpen(false)}
              >
                <div className="flex items-center">
                  <seminovosButton.icon className="w-6 h-6 mr-2" />
                  {seminovosButton.name}
                </div>
              </Link>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}