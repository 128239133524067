import { Suspense, lazy } from 'react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import PublicLayout from './components/layout/PublicLayout';
import AdminLayout from './components/layout/AdminLayout';
import ErrorBoundary from './components/ErrorBoundary';
import { useAuth } from './contexts/AuthContext';

// Loading component
const LoadingSpinner = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#71100f]"></div>
  </div>
);

// Lazy loaded components with Suspense
const withSuspense = (Component: React.ComponentType) => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Component />
    </Suspense>
  );
};

// Public pages
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const Clients = lazy(() => import('./pages/Clients'));
const Report = lazy(() => import('./pages/Report'));
const Vehicles = lazy(() => import('./pages/Vehicles'));
const VehicleDetails = lazy(() => import('./pages/VehicleDetails'));
const Location = lazy(() => import('./pages/Location'));
const News = lazy(() => import('./pages/News'));
const NewsArticle = lazy(() => import('./pages/NewsArticle'));
const Careers = lazy(() => import('./pages/Careers'));
const Login = lazy(() => import('./pages/auth/Login'));
const Privacy = lazy(() => import('./pages/Privacy'));
const RecruitmentManager = lazy(() => import('./pages/admin/RecruitmentManager'));

// Admin pages
const Dashboard = lazy(() => import('./pages/admin/Dashboard'));
const ClientsManager = lazy(() => import('./pages/admin/ClientsManager'));
const NewsManager = lazy(() => import('./pages/admin/NewsManager'));
const VehiclesManager = lazy(() => import('./pages/admin/VehiclesManager'));
const MessagesManager = lazy(() => import('./pages/admin/MessagesManager'));
const LocationManager = lazy(() => import('./pages/admin/LocationManager'));
const BannerManager = lazy(() => import('./pages/admin/BannerManager'));
const UserManager = lazy(() => import('./pages/admin/UserManager'));
const Settings = lazy(() => import('./pages/admin/Settings'));
const Profile = lazy(() => import('./pages/admin/Profile'));
const SearchResults = lazy(() => import('./pages/admin/SearchResults'));
const TestSupabase = lazy(() => import('./pages/test-supabase'));
const ReportsManager = lazy(() => import('./pages/admin/ReportsManager'));
const PageManager = lazy(() => import('./pages/admin/PageManager'));

// Protected route component
function ProtectedRoute() {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
}

// Route configuration
export const router = createBrowserRouter([
  // Configuração das rotas
  {
    path: '/',
    element: <PublicLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      { index: true, element: withSuspense(Home) },
      { path: 'about', element: withSuspense(About) },
      { path: 'contact', element: withSuspense(Contact) },
      { path: 'clients', element: withSuspense(Clients) },
      { path: 'report', element: withSuspense(Report) },
      { path: 'vehicles', element: withSuspense(Vehicles) },
      { path: 'vehicles/:id', element: withSuspense(VehicleDetails) },
      { path: 'location', element: withSuspense(Location) },
      { path: 'news', element: withSuspense(News) },
      { path: 'news/article/:id', element: withSuspense(NewsArticle) },
      { path: 'careers', element: withSuspense(Careers) },
      { path: 'privacy', element: withSuspense(Privacy) },
      { path: 'login', element: withSuspense(Login) },
    ],
  },
  {
    path: '/login',
    element: withSuspense(Login),
    errorElement: <ErrorBoundary />
  },
  {
    path: '/admin',
    element: <ProtectedRoute />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <AdminLayout />,
        children: [
          { index: true, element: withSuspense(Dashboard) },
          { path: 'dashboard', element: withSuspense(Dashboard) },
          { path: 'clients', element: withSuspense(ClientsManager) },
          { path: 'news', element: withSuspense(NewsManager) },
          { path: 'vehicles', element: withSuspense(VehiclesManager) },
          { path: 'messages', element: withSuspense(MessagesManager) },
          { path: 'location', element: withSuspense(LocationManager) },
          { path: 'banners', element: withSuspense(BannerManager) },
          { path: 'users', element: withSuspense(UserManager) },
          { path: 'settings', element: withSuspense(Settings) },
          { path: 'profile', element: withSuspense(Profile) },
          { path: 'search', element: withSuspense(SearchResults) },
          { path: 'reports', element: withSuspense(ReportsManager) },
          { path: 'pages', element: withSuspense(PageManager) },
          { path: 'recruitment', element: withSuspense(RecruitmentManager) },
          { path: 'test-supabase', element: withSuspense(TestSupabase) },
        ],
      }
    ],
  },
  {
    path: '*',
    element: <ErrorBoundary />
  }
], {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true
  }
});
