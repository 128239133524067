import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Bell, Search, User, Settings, LogOut, Menu } from 'lucide-react';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '../ui/avatar';
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { useAuth } from "../../contexts/AuthContext";
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { Notification } from '../../types/notification';
import { toast } from 'react-hot-toast';
import logo from '../../assets/logo2.png';
import { format } from 'date-fns';

const AdminHeader = () => {
  const { user, logout } = useAuth();
  const supabase = useSupabaseClient();
  const navigate = useNavigate();
  
  // User information
  const userFullName = user?.user_metadata?.full_name || 'Usuário';
  const userEmail = user?.email || '';
  const userInitials = userFullName
    .split(' ')
    .map((name: string) => name[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);

  // State
  const [searchQuery, setSearchQuery] = useState("");
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user?.id) {
      fetchNotifications();
    }
  }, [user?.id]);

  const fetchNotifications = async () => {
    try {
      const { data, error } = await supabase
        .from('notifications')
        .select('*')
        .eq('user_id', user?.id)
        .order('created_at', { ascending: false })
        .limit(10);

      if (error) throw error;
      setNotifications(data || []);
    } catch (error: any) {
      console.error('Error fetching notifications:', error);
      toast.error('Erro ao carregar notificações');
    }
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/admin/search?q=${encodeURIComponent(searchQuery.trim())}`);
      setSearchQuery("");
    }
  };

  const handleNotificationClick = async (notification: Notification) => {
    try {
      // Marca a notificação como lida
      const { error } = await supabase
        .from('notifications')
        .update({ read: true })
        .eq('id', notification.id);

      if (error) throw error;

      // Atualiza o estado local
      setNotifications(notifications.map(n => 
        n.id === notification.id ? { ...n, read: true } : n
      ));

      // Navega para a página apropriada baseado no tipo de notificação
      if (notification.type === 'message') {
        navigate('/admin/messages');
      } else if (notification.type === 'news') {
        navigate('/admin/news');
      } else if (notification.type === 'profile') {
        navigate('/admin/profile');
      }

      toast.success('Notificação marcada como lida');
    } catch (error) {
      console.error('Erro ao marcar notificação como lida:', error);
      toast.error('Erro ao marcar notificação como lida');
    }
  };

  const markAllNotificationsAsRead = async () => {
    try {
      const unreadNotifications = notifications.filter(n => !n.read);
      if (unreadNotifications.length === 0) return;

      const { error } = await supabase
        .from('notifications')
        .update({ read: true })
        .eq('user_id', user?.id)
        .in('id', unreadNotifications.map(n => n.id));

      if (error) throw error;

      setNotifications(notifications.map(notification => ({
        ...notification,
        read: true
      })));
    } catch (error: any) {
      console.error('Error marking notifications as read:', error);
      toast.error('Erro ao marcar notificações como lidas');
    }
  };

  const handleDropdownOpenChange = (open: boolean) => {
    setIsDropdownOpen(open);
    if (open && notifications.some(n => !n.read)) {
      markAllNotificationsAsRead();
    }
  };

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Erro ao fazer logout');
    } finally {
      setIsLoading(false);
    }
  };

  const unreadCount = notifications.filter(n => !n.read).length;

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-white shadow-sm">
      <div className="container flex h-16 max-w-screen-2xl items-center justify-between px-4 md:px-8">
        {/* Logo e Pesquisa */}
        <div className="flex items-center flex-1 gap-8">
          <Link to="/admin" className="flex-shrink-0">
            <img src={logo} alt="Indiana Seguros" className="h-8 w-auto" />
          </Link>

          {/* Pesquisa */}
          <form onSubmit={handleSearch} className="hidden sm:flex flex-1 max-w-md">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
              <Input
                placeholder="Pesquisar..."
                className="w-full pl-10 pr-4 py-2 bg-gray-50 border-gray-200 focus:bg-white"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </form>
        </div>

        {/* Ações e Perfil */}
        <div className="flex items-center gap-2">
          {/* Menu Mobile */}
          <Button
            variant="ghost"
            className="sm:hidden"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <Menu className="h-5 w-5" />
            <span className="sr-only">Menu</span>
          </Button>

          {/* Notificações */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" className="relative">
                <Bell className="h-5 w-5 text-gray-600" />
                {notifications.filter(n => !n.read).length > 0 && (
                  <span className="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-[#71100f] text-[10px] font-medium text-white">
                    {notifications.filter(n => !n.read).length}
                  </span>
                )}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-80">
              <DropdownMenuLabel className="font-normal">
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-medium">Notificações</p>
                  <p className="text-xs text-gray-500">
                    Você tem {notifications.filter(n => !n.read).length} notificações não lidas
                  </p>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              {notifications.length === 0 ? (
                <div className="py-4 px-3 text-sm text-gray-500 text-center">
                  Nenhuma notificação
                </div>
              ) : (
                notifications.map((notification) => (
                  <DropdownMenuItem
                    key={notification.id}
                    className={`p-3 cursor-pointer focus:bg-gray-100 hover:bg-gray-100 ${!notification.read ? 'bg-gray-50' : ''}`}
                    onClick={() => handleNotificationClick(notification)}
                  >
                    <div className="flex flex-col space-y-1">
                      <div className="flex items-center justify-between">
                        <p className={`text-sm ${!notification.read ? 'font-medium' : ''}`}>
                          {notification.title}
                        </p>
                        <span className="text-xs text-gray-500">
                          {format(new Date(notification.created_at), "dd/MM/yyyy")}
                        </span>
                      </div>
                      <p className="text-xs text-gray-500">{notification.message}</p>
                    </div>
                  </DropdownMenuItem>
                ))
              )}
              {notifications.length > 0 && (
                <>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    className="p-2 text-center text-sm text-[#71100f] cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
                    onClick={async () => {
                      try {
                        await supabase
                          .from('notifications')
                          .update({ read: true })
                          .in('id', notifications.filter(n => !n.read).map(n => n.id));
                        
                        setNotifications(notifications.map(n => ({ ...n, read: true })));
                        toast.success('Todas as notificações foram marcadas como lidas');
                      } catch (error) {
                        console.error('Erro ao marcar todas notificações como lidas:', error);
                        toast.error('Erro ao marcar notificações como lidas');
                      }
                    }}
                  >
                    Marcar todas como lidas
                  </DropdownMenuItem>
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>

          {/* Perfil */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="relative flex items-center gap-2 px-2">
                <Avatar className="h-9 w-9">
                  <AvatarImage src={user?.user_metadata?.avatar_url} alt={userFullName} />
                  <AvatarFallback className="bg-[#71100f] text-white">
                    {userInitials}
                  </AvatarFallback>
                </Avatar>
                <div className="hidden md:flex flex-col items-start">
                  <span className="text-sm font-medium">{userFullName}</span>
                  <span className="text-xs text-gray-500">Administrador</span>
                </div>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="end">
              <DropdownMenuLabel className="font-normal">
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-medium leading-none">{userFullName}</p>
                  <p className="text-xs leading-none text-gray-500">{userEmail}</p>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem asChild className="cursor-pointer focus:bg-gray-100 hover:bg-gray-100">
                <Link to="/admin/profile" className="flex items-center gap-2 w-full">
                  <User className="h-4 w-4" />
                  <span>Perfil</span>
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild className="cursor-pointer focus:bg-gray-100 hover:bg-gray-100">
                <Link to="/admin/settings" className="flex items-center gap-2 w-full">
                  <Settings className="h-4 w-4" />
                  <span>Configurações</span>
                </Link>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem 
                className="flex items-center gap-2 text-red-600 cursor-pointer focus:bg-red-50 hover:bg-red-50" 
                onClick={handleLogout}
                disabled={isLoading}
              >
                <LogOut className="h-4 w-4" />
                <span>{isLoading ? 'Saindo...' : 'Sair'}</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      {/* Pesquisa Mobile */}
      <div className={`sm:hidden border-t ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
        <div className="container py-3 px-4">
          <form onSubmit={handleSearch} className="flex">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
              <Input
                placeholder="Pesquisar..."
                className="w-full pl-10 pr-4 py-2 bg-gray-50 border-gray-200 focus:bg-white"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </form>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
