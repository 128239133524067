import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { 
  Home, 
  Car, 
  Users, 
  Mail, 
  Newspaper, 
  MapPin, 
  Settings,
  UserCog,
  Image,
  Search,
  AlertTriangle,
  UserPlus,
  Building2,
  BellRing,
  LogOut,
  File,
  Briefcase
} from 'lucide-react';
import ScrollToTop from '../ScrollToTop';
import AdminHeader from './AdminHeader';

type MenuItem = {
  icon: JSX.Element;
  label: string;
  path: string;
};

type MenuCategory = {
  label: string;
  items: MenuItem[];
};

const AdminLayout: React.FC = () => {
  const location = useLocation();

  const menuCategories: MenuCategory[] = [
    {
      label: 'Principal',
      items: [
        { icon: <Home size={20} />, label: 'Dashboard', path: '/admin' },
      ]
    },
    {
      label: 'Conteúdo',
      items: [
        { icon: <Car size={20} />, label: 'Veículos', path: '/admin/vehicles' },
        { icon: <Building2 size={20} />, label: 'Clientes', path: '/admin/clients' },
        { icon: <Newspaper size={20} />, label: 'Notícias', path: '/admin/news' },
        { icon: <Image size={20} />, label: 'Banners', path: '/admin/banners' },
        { icon: <File size={20} />, label: 'Páginas', path: '/admin/pages' },
        { icon: <MapPin size={20} />, label: 'Localização', path: '/admin/location' },
      ]
    },
    {
      label: 'Comunicação',
      items: [
        { icon: <Mail size={20} />, label: 'Mensagens', path: '/admin/messages' },
        { icon: <AlertTriangle size={20} />, label: 'Denúncias', path: '/admin/reports' },
      ]
    },
    {
      label: 'RH',
      items: [
        { icon: <UserPlus size={20} />, label: 'Recrutamento', path: '/admin/recruitment' },
      ]
    },
    {
      label: 'Sistema',
      items: [
        { icon: <Users size={20} />, label: 'Usuários', path: '/admin/users' },
        { icon: <Settings size={20} />, label: 'Configurações', path: '/admin/settings' },
      ]
    },
  ];

  return (
    <div className="min-h-screen bg-background z-10">
      <ScrollToTop />
      <AdminHeader />
      <div className="flex h-[calc(100vh-4.5rem)]">
        <div className="w-64 border-r border-gray-200 bg-white overflow-y-auto">
          <nav className="p-4">
            {menuCategories.map((category, index) => (
              <div key={category.label} className={index > 0 ? 'mt-6' : ''}>
                <h2 className="px-4 text-xs font-semibold text-gray-500 uppercase tracking-wider mb-2">
                  {category.label}
                </h2>
                <div className="space-y-1">
                  {category.items.map((item) => (
                    <Link
                      key={item.path}
                      to={item.path}
                      className={`flex items-center gap-2 px-4 py-2 text-sm rounded-lg ${
                        location.pathname === item.path
                          ? 'bg-[#71100f] text-white'
                          : 'text-gray-600 hover:bg-gray-100'
                      }`}
                    >
                      {item.icon}
                      <span>{item.label}</span>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </nav>
        </div>
        <main className="flex-1 overflow-y-auto p-8 bg-background">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
