import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://omblsqvmxdlqnwsfxmga.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9tYmxzcXZteGRscW53c2Z4bWdhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzI5MDg4NzgsImV4cCI6MjA0ODQ4NDg3OH0.AD9ZgoGCmVn8muvEROOkWzfStvNEM7-8TQCOESfwu4U';
const supabaseServiceKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9tYmxzcXZteGRscW53c2Z4bWdhIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTczMjkwODg3OCwiZXhwIjoyMDQ4NDg0ODc4fQ.2Zwd_Vf3QfmsqaHDMHQ52zCi4rcnDnJRb-xNpygTzhc';

// Cria uma única instância do cliente Supabase
let supabaseInstance: ReturnType<typeof createClient> | null = null;

function getSupabaseInstance() {
  if (!supabaseInstance) {
    supabaseInstance = createClient(supabaseUrl, supabaseAnonKey, {
      auth: {
        autoRefreshToken: true,
        persistSession: true,
        storageKey: 'supabase.auth.token',
        storage: typeof window !== 'undefined' ? window.localStorage : undefined
      }
    });
  }
  return supabaseInstance;
}

// Exporta a instância única do cliente Supabase
export const supabase = getSupabaseInstance();

// Usa a mesma instância para operações do servidor
export const supabaseAdmin = supabase;

// Helper function to test connection (can be called from components)
export async function testSupabaseConnection() {
  try {
    const { data, error } = await supabase
      .from('settings')
      .select('*')
      .limit(1)
      .single();

    if (error) {
      console.error('Error connecting to Supabase:', error);
      return false;
    }
    return true;
  } catch (error) {
    console.error('Error testing Supabase connection:', error);
    return false;
  }
}