import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Phone, 
  Mail, 
  MapPin, 
  Linkedin, 
  Youtube,
  Facebook,
  Instagram,
  Clock,
  ChevronRight,
  Shield
} from 'lucide-react';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { Location } from '../../types/location';

export default function Footer() {
  const [location, setLocation] = useState<Location | null>(null);
  const supabase = useSupabaseClient();

  useEffect(() => {
    const fetchMainLocation = async () => {
      try {
        const { data, error } = await supabase
          .from('locations')
          .select('*')
          .eq('status', 'active')
          .order('created_at', { ascending: false })
          .limit(1);

        if (error) {
          console.error('Error fetching location:', error);
          return;
        }

        if (data && data.length > 0) {
          setLocation(data[0]);
        }
      } catch (err) {
        console.error('Error in fetchMainLocation:', err);
      }
    };

    fetchMainLocation();
  }, [supabase]);

  return (
    <footer className="bg-gradient-to-b from-gray-900 to-gray-950 text-white">
      {/* Main Footer */}
      <div className="py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
            {/* Company Info */}
            <div className="space-y-6 flex flex-col items-center text-center lg:items-start lg:text-left">
              <img 
                src="/images/logo indiana branco.png" 
                alt="Indiana Transportes"
                className="h-16 w-auto"
                onError={(e) => {
                  console.error('Error loading image:', e);
                }}
              />
              <p className="text-gray-400 leading-relaxed">
                Soluções em transporte e logística com excelência e compromisso há mais de 30 anos no mercado.
              </p>
              <div className="flex space-x-6">
                <a
                  href="https://www.linkedin.com/company/tindiana-transportes/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">LinkedIn</span>
                  <Linkedin className="h-6 w-6" />
                </a>
                <a
                  href="https://www.facebook.com/tindiana"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Facebook</span>
                  <Facebook className="h-6 w-6" />
                </a>
                <a
                  href="https://www.instagram.com/tindiana"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Instagram</span>
                  <Instagram className="h-6 w-6" />
                </a>
              </div>
              <Link to="/login" className="text-gray-400 hover:text-blue-400 transition-colors inline-flex items-center group mt-4">
                <ChevronRight className="h-4 w-4 mr-2 transform group-hover:translate-x-1 transition-transform" />
                Acessar Painel Administrativo
              </Link>
            </div>

            {/* Quick Links */}
            <div className="space-y-6">
              <h3 className="text-lg font-bold relative pl-4 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:h-full before:w-1 before:bg-blue-500">
                Links Rápidos
              </h3>
              <ul className="space-y-3">
                <li>
                  <Link to="/about" className="text-gray-400 hover:text-blue-400 transition-colors inline-flex items-center group">
                    <ChevronRight className="h-4 w-4 mr-2 transform group-hover:translate-x-1 transition-transform" />
                    Sobre Nós
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="text-gray-400 hover:text-blue-400 transition-colors inline-flex items-center group">
                    <ChevronRight className="h-4 w-4 mr-2 transform group-hover:translate-x-1 transition-transform" />
                    Serviços
                  </Link>
                </li>
                <li>
                  <Link to="/clients" className="text-gray-400 hover:text-blue-400 transition-colors inline-flex items-center group">
                    <ChevronRight className="h-4 w-4 mr-2 transform group-hover:translate-x-1 transition-transform" />
                    Clientes
                  </Link>
                </li>
                <li>
                  <Link to="/location" className="text-gray-400 hover:text-blue-400 transition-colors inline-flex items-center group">
                    <ChevronRight className="h-4 w-4 mr-2 transform group-hover:translate-x-1 transition-transform" />
                    Onde Estamos
                  </Link>
                </li>
                <li>
                  <Link to="/careers" className="text-gray-400 hover:text-blue-400 transition-colors inline-flex items-center group">
                    <ChevronRight className="h-4 w-4 mr-2 transform group-hover:translate-x-1 transition-transform" />
                    Trabalhe Conosco
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-gray-400 hover:text-blue-400 transition-colors inline-flex items-center group">
                    <ChevronRight className="h-4 w-4 mr-2 transform group-hover:translate-x-1 transition-transform" />
                    Contato
                  </Link>
                </li>
                <li>
                  <Link to="/privacidade" className="text-gray-400 hover:text-blue-400 transition-colors inline-flex items-center group">
                    <ChevronRight className="h-4 w-4 mr-2 transform group-hover:translate-x-1 transition-transform" />
                    Política de Privacidade
                  </Link>
                </li>
              </ul>
            </div>

            {/* Contact Info */}
            <div className="space-y-6">
              <h3 className="text-lg font-bold relative pl-4 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:h-full before:w-1 before:bg-blue-500">
                Contato
              </h3>
              <ul className="space-y-4">
                <li className="flex items-center space-x-3 group">
                  <div className="p-2 bg-blue-500/10 rounded-lg group-hover:bg-blue-500/20 transition-colors">
                    <Phone className="h-5 w-5 text-blue-400" />
                  </div>
                  <span className="text-gray-400">(43) 3375-5800</span>
                </li>
                <li className="flex items-center space-x-3 group">
                  <div className="p-2 bg-blue-500/10 rounded-lg group-hover:bg-blue-500/20 transition-colors">
                    <Mail className="h-5 w-5 text-blue-400" />
                  </div>
                  <span className="text-gray-400">indiana@indianatransportes.com.br</span>
                </li>
                <li className="flex items-center space-x-3 group">
                  <div className="p-2 bg-blue-500/10 rounded-lg group-hover:bg-blue-500/20 transition-colors">
                    <MapPin className="h-5 w-5 text-blue-400" />
                  </div>
                  <span className="text-gray-400">
                    {location ? `${location.address}, ${location.number} | ${location.city} - ${location.state}` : 'Carregando...'}
                  </span>
                </li>
                <li className="flex items-center space-x-3 group">
                  <div className="p-2 bg-blue-500/10 rounded-lg group-hover:bg-blue-500/20 transition-colors">
                    <Clock className="h-5 w-5 text-blue-400" />
                  </div>
                  <span className="text-gray-400">
                    Seg - Sex: 8h às 18h | Sab: 8h às 12h
                  </span>
                </li>
                <li className="flex items-center space-x-3 group">
                  <div className="p-2 bg-blue-500/10 rounded-lg group-hover:bg-blue-500/20 transition-colors">
                    <Shield className="h-5 w-5 text-blue-400" />
                  </div>
                  <Link to="/report" className="text-gray-400 hover:text-blue-400 transition-colors">
                    Canal de Denúncia
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Footer */}
      <div className="border-t border-gray-800 py-6">
        <div className="container mx-auto px-4">
          <p className="text-center text-gray-400 text-sm">
            &copy; {new Date().getFullYear()} Copyright - Tindiana Logística e Transportes LTDA. Todos os direitos reservados - L&P Technologies.
          </p>
        </div>
      </div>
    </footer>
  );
}