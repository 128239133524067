import * as React from "react"
import { Link, type LinkProps } from "react-router-dom"
import { cn } from "../../lib/utils"

type ButtonBaseProps = {
  variant?: 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'link'
  size?: 'default' | 'sm' | 'lg' | 'icon'
  isLoading?: boolean
  asLink?: boolean
  to?: string
}

type ButtonAsButtonProps = ButtonBaseProps & 
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof ButtonBaseProps> & {
    asLink?: false
  }

type ButtonAsLinkProps = ButtonBaseProps & 
  Omit<LinkProps, keyof ButtonBaseProps | 'to'> & {
    asLink: true
    to: string
  }

type ButtonProps = ButtonAsButtonProps | ButtonAsLinkProps

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  ({ className, variant = "default", size = "default", isLoading, asLink, to, children, ...props }, ref) => {
    if (asLink && to) {
      const linkProps = props as Omit<ButtonAsLinkProps, keyof ButtonBaseProps>
      return (
        <Link
          to={to}
          className={cn(
            "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background",
            {
              "bg-primary text-primary-foreground hover:bg-primary/90": variant === "default",
              "bg-destructive text-destructive-foreground hover:bg-destructive/90": variant === "destructive",
              "border border-input hover:bg-accent hover:text-accent-foreground": variant === "outline",
              "bg-secondary text-secondary-foreground hover:bg-secondary/80": variant === "secondary",
              "hover:bg-accent hover:text-accent-foreground": variant === "ghost",
              "underline-offset-4 hover:underline text-primary": variant === "link",
              "h-10 py-2 px-4": size === "default",
              "h-9 px-3": size === "sm",
              "h-11 px-8": size === "lg",
              "h-10 w-10": size === "icon",
            },
            className
          )}
          ref={ref as React.Ref<HTMLAnchorElement>}
          {...linkProps}
        >
          {children}
        </Link>
      )
    }

    const buttonProps = props as ButtonAsButtonProps
    return (
      <button
        type={buttonProps.type || "button"}
        className={cn(
          "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background",
          {
            "bg-primary text-primary-foreground hover:bg-primary/90": variant === "default",
            "bg-destructive text-destructive-foreground hover:bg-destructive/90": variant === "destructive",
            "border border-input hover:bg-accent hover:text-accent-foreground": variant === "outline",
            "bg-secondary text-secondary-foreground hover:bg-secondary/80": variant === "secondary",
            "hover:bg-accent hover:text-accent-foreground": variant === "ghost",
            "underline-offset-4 hover:underline text-primary": variant === "link",
            "h-10 py-2 px-4": size === "default",
            "h-9 px-3": size === "sm",
            "h-11 px-8": size === "lg",
            "h-10 w-10": size === "icon",
          },
          className
        )}
        ref={ref as React.Ref<HTMLButtonElement>}
        {...buttonProps}
      >
        {isLoading ? (
          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white" />
        ) : (
          children
        )}
      </button>
    )
  }
)

Button.displayName = "Button"

export { Button, type ButtonProps }
